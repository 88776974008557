export const timeOptions = [
  { label: "Full Time", value: "full-time" },
  { label: "Contract", value: "contract" },
  { label: "Part Time", value: "part-time" },
  {
    label: "Full Time/Part Time/Contract",
    value: "full-time-part-time-contract",
  },
  { label: "Full Time/Part Time", value: "full-time-part-time" },
];

export const levelOptions = [
  { label: "Mid Level", value: "mid-level" },
  { label: "Senior Level", value: "senior-level" },
  { label: "Entry Level", value: "entry-level" },
  { label: "Intern Level", value: "intern-level" },
  { label: "Top Level", value: "top-level" },
];

export const genderOptions = [
  { label: "Both", value: "o" },
  { label: "Female", value: "f" },
  { label: "Male", value: "m" },
];

export const educationOptions = [
  { label: "Bachelor's", value: "bachelors" },
  { label: "Master's", value: "masters" },
  { label: "Intermediate", value: "intermediate" },
  { label: "SLC", value: "slc" },
  { label: "Phd.", value: "phd" },
  { label: "Under SLC", value: "under-slc" },
];
