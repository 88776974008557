import DynamicRegistration from "../../DynamicRegistration";

const JobSeekerRegistration = () => {
  return (
    <div>
      <DynamicRegistration />
    </div>
  );
};

export default JobSeekerRegistration;
