import DynamicLogin from "../../DynamicLogin";

const JobSeekerLogin = () => {
  return (
    <div>
      <DynamicLogin />
    </div>
  );
};

export default JobSeekerLogin;
