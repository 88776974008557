import AntdBreadCum from "../../common/AntdBreadCum";
import AntdButton from "../../common/AntdButtons";
import Inputs from "../../common/form/AntdInputs";

const ChangePassword = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-4 sm:p-6 lg:p-8">
      <div className="mb-6">
        <AntdBreadCum array={["Employee", "Change Password"]} />
      </div>
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-[#3d2462] mb-6">
          Change Password
        </h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          <div className="col-span-1">
            <Inputs className="w-full" Label="Old Password" type="password" />
          </div>
          <div className="col-span-1">
            <Inputs className="w-full" Label="New Password" type="password" />
          </div>
          <div className="col-span-1">
            <Inputs
              className="w-full"
              Label="Confirm Password"
              type="password"
            />
          </div>
          <div className="col-span-1 md:col-span-2 lg:col-span-3">
            <AntdButton classNames="bg-[#08142c] text-white font-semibold px-4 rounded hover:!bg-[#0a223f] transition-colors">
              Update
            </AntdButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
